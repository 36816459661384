@import "~antd/dist/antd.css";

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
/*rewrite antd style*/
a,
a:hover,
.ant-pagination-item-active a,
.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  -webkit-color: #d52b1e;
  color: #d52b1e;
}

.ant-pagination-item-active a,
.ant-pagination-item-active,
.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
  border-color: #d52b1e !important;
}
/*ant-step-dot*/
.ant-steps-dot .ant-steps-item:first-of-type .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-of-type .ant-steps-icon-dot {
  left: 0;
  border-color: #c7f3cf;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.ant-steps-dot
  .ant-steps-item-process
  .ant-steps-icon:first-of-type
  .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small
  .ant-steps-item-process
  .ant-steps-icon:first-of-type
  .ant-steps-icon-dot {
  left: -4px;
  border-color: #a3e3d9;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.treatment-date .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  left: -4px;
  border-color: #50c0f0;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.ant-drawer .ant-drawer-content-wrapper .ant-drawer-content {
  background-color: #f1f1f1 !important;
}
.ant-drawer-body {
  padding: 3rem 3.125rem !important;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #d52b1e;
}
.ant-input:hover {
  border-color: #d52b1e;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #d52b1e;
  box-shadow: 0 0 0 2px rgba(213, 43, 30, 0.2);
}

/*ant-date-picker*/
.ant-picker-today-btn {
  color: #d52b1e;
}
.ant-picker-focused,
.ant-picker:hover,
.ant-picker-focused {
  border-color: #d52b1e;
  box-shadow: 0 0 0 2px rgba(213, 43, 30, 0.2);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #d52b1e;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #d52b1e;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d52b1e;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d52b1e;
  box-shadow: 0 0 0 2px rgba(213, 43, 30, 0.2);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(213, 43, 30, 0.2);
}

.ant-radio-checked::after {
  border-color: #d52b1e;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #d52b1e;
}
.ant-radio-inner::after {
  background: #d52b1e;
}
html {
  --antd-wave-shadow-color: #d52b1e;
}
/* .ant-btn, */
.ant-btn:focus,
.ant-btn:active,
.ant-btn:hover {
  color: #d52b1e;
  /* background: #d52b1e; */
  border-color: #d52b1e;
}

.ant-btn-primary,
.ant-btn-primary:hover {
  color: #ffffff;
  background: #d52b1e;
  border-color: #d52b1e;
}

.my_sync.spin {
  -webkit-animation: sync-logo-spin 1s ease-out 0s infinite normal;
  animation: sync-logo-spin 1s ease-out 0s infinite normal;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #d52b1e;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #d52b1e;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d52b1e;
  border-color: #d52b1e;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #d52b1e;
}
.ant-checkbox-checked::after {
  border: 1px solid #d52b1e;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d52b1e;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d52b1e;
  box-shadow: 0 0 0 2px rgb(213 43 30 / 20%);
}

.ScrollbarsCustom .ScrollbarsCustom-Thumb {
  background: rgba(213, 43, 30, 0.9) !important;
}

.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: #d52b1e;
  border-color: #d52b1e;
  color: #ffffff;
}
.ant-btn-primary:active {
  opacity: 0.8;
}

@keyframes sync-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*for safari*/
.MuiButton-containedPrimary.Mui-disabled {
  -webkit-color: #222222;
  -webkit-background: #eeeeee;
  color: #222222;
  background: #eeeeee;
}
/*MUI*/
a.MuiTypography-root {
  text-decoration: none;
}
button.MuiButton-containedPrimary:hover {
  background: #d52b1e;
}
.MuiInput-underline:after {
  border-color: #222222;
}

/* Slider component */
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #d52b1e;
  border-radius: 2px;
  transition: background-color .3s
}

.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #d52b1e;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color .3s,box-shadow .6s,transform .3s cubic-bezier(.18,.89,.32,1.28)
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #d52b1e;
  box-shadow: 0 0 0 5px #1890ff1f
}
.ant-slider-handle:focus {
  border-color: #d52b1e;
  outline: none;
  box-shadow: 0 0 0 5px #1890ff1f
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #d52b1e
}
.ant-slider:hover .ant-slider-track {
  background-color: #d52b1e
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #d52b1e
}
.ant-slider-dot-active {
  border-color: #d52b1e
}
