.map-page-container {
  display: flex;
  flex-direction: row !important;
  overflow: hidden;

  .map-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .sidebar {
    padding: 3.4rem 1rem 3.4rem 3rem;
    max-width: 400px;
    min-width: 25rem;
    background-color: #f1f1f1;
    // height: 100%;
    // overflow: scroll;
    .map-sidebar-scrollbar {
    }
    .title {
      color: #000000;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
    .desc {
      color: #000000;
      font-size: 1rem;
    }
    .updator {
      color: #a59d95;
      font-size: 0.75rem;
    }
    .selector-wrapper {
      padding-right: 1.875rem;
      padding-bottom: 30px;
      padding-top: 30px;
      .selector-item {
        margin-bottom: 1.5rem;
        &.previous {
          padding-bottom: 1.5rem;
          border-bottom: 1px solid #979797;
        }

        .label {
          color: #82786f;
          font-size: 0.625rem;
          margin-bottom: 0.75rem;
        }
        .upcoming-trial-label{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .MuiSvgIcon-root.MuiSelect-icon {
          color: #82786f;
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      .save-filters-btn:hover {
        color: #ffffff;
      }
    }
  }
}


.states-multiple-selector {
  .ant-select-selection-item {
    .ant-checkbox {
      display: none;
    }
  }
  .ant-select-item-option-state {
    display: none;
  }
  .anticon.anticon-check {
    display: none;
  }
}
.states-item .ant-select-item-option-state {
  display: none;
}

.oncology-stage-selector .ant-select-item.ant-select-item-group {
  color: rgb(0, 0, 0);
  font-weight: 500;
  background-color: rgb(0, 0, 0, 0.1);
}
.oncology-stage-selector .ant-select-item-option-content {
  white-space: normal !important;
}

.selector-item {
  .MuiAutocomplete-listbox .MuiAutocomplete-option {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.85);
  }
}

.swith-wrapper{
  .filtered-sites{
    color:#000000;
  }
            
}
.table-section{
  position: absolute;
  bottom: 0;
  max-height: 70%;
  overflow: scroll;
  width: 100%;
  .ant-table-wrapper{
    background: #ffffff;
    height: 80%;
  }
  .table-controller {
    display: inline-block;
    margin-bottom: 1rem;

    .btn-wrapper {
      padding: 6px 10px;
      background-color: #ffffff;
      .ant-btn-primary,
      .ant-btn-primary:hover {
        color: #ffffff;
      }
    }
  }
}
