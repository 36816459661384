#legends-wrapper {
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    color: #666666;
    font-size: 14px;
    cursor: pointer;
    .legend-icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 4px;

      &.clinics {
        background-color: green;
      }
      &.sites {
        background-color: yellow;
      }
    }
  }
}

.choropleth-legends {
  padding: 20px;
  margin-right: 50px;

  .y-axis {
    line-height: 0;
  }
  i {
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  // .y-axis.high {
  //   .low {
  //     background-color: #be65ac;
  //   }
  //   .medium {
  //     background-color: #8d62aa;
  //   }
  //   .high {
  //     background-color: #3c4994;
  //   }
  // }
  .y-axis.high.RdBu {
    .low {
      background-color: #64acbe;
    }
    .medium {
      background-color: #627f8c;
    }
    .high {
      background-color: #574249;
    }
  }
  .y-axis.high.BuPu {
    .low {
      background-color: #be64ac;
    }
    .medium {
      background-color: #8c62aa;
    }
    .high {
      background-color: #3b4994;
    }
  }
  .y-axis.high.GnBu {
    .low {
      background-color: #73ae80;
    }
    .medium {
      background-color: #5a9178;
    }
    .high {
      background-color: #2a5a5b;
    }
  }
  .y-axis.high.PuOr {
    .low {
      background-color: #9972af;
    }
    .medium {
      background-color: #976b82;
    }
    .high {
      background-color: #804d36;
    }
  }

  // .y-axis.medium {
  //   .low {
  //     background-color: #dfb0d6;
  //   }
  //   .medium {
  //     background-color: #a4aed3;
  //   }
  //   .high {
  //     background-color: #5698b9;
  //   }
  // }
  .y-axis.medium.RdBu {
    .low {
      background-color: #b0d5df;
    }
    .medium {
      background-color: #ad9ea5;
    }
    .high {
      background-color: #985356;
    }
  }
  .y-axis.medium.BuPu {
    .low {
      background-color: #dfb0d6;
    }
    .medium {
      background-color: #a5add3;
    }
    .high {
      background-color: #5698b9;
    }
  }

  .y-axis.medium.GnBu {
    .low {
      background-color: #b8d6be;
    }
    .medium {
      background-color: #90b2b3;
    }
    .high {
      background-color: #567994;
    }
  }
  .y-axis.medium.PuOr {
    .low {
      background-color: #cbb8d7;
    }
    .medium {
      background-color: #c8ada0;
    }
    .high {
      background-color: #af8e53;
    }
  }
  .y-axis.low.RdBu {
    .low {
      background-color: #c0c0c0;
      border-left: 1px solid #b3b3b3;
    }
    .medium {
      background-color: #e4acac;
    }
    .high {
      background-color: #c85a5a;
    }
  }
  .y-axis.low.BuPu {
    .low {
      background-color: #c0c0c0;
      border-left: 1px solid #b3b3b3;
    }
    .medium {
      background-color: #ace4e4;
    }
    .high {
      background-color: #5ac8c8;
    }
  }
  .y-axis.low.GnBu {
    .low {
      background-color: #c0c0c0;
      border-left: 1px solid #b3b3b3;
    }
    .medium {
      background-color: #b5c0da;
    }
    .high {
      background-color: #6c83b5;
    }
  }
  .y-axis.low.PuOr {
    .low {
      background-color: #c0c0c0;
      border-left: 1px solid #b3b3b3;
    }
    .medium {
      background-color: #e4d9ac;
    }
    .high {
      background-color: #c8b35a;
    }
  }

  .x.axis-label {
    width: 75px;
    .axis-line {
      position: relative;
      margin-bottom: 5px;
    }
    .anticon {
      position: absolute;
      top: -4px;
      right: -7px;
    }
    span.text {
      font-size: 12px;
    }
  }
  &.quarter .x.axis-label .anticon{
    right: -32px;
  }

  .y.axis-label {
    position: relative;
    .axis-line {
      position: relative;
    }
    .anticon {
      position: absolute;
      left: -5px;
      bottom: 72px;
      left: -4px;
    }
    span.text {
      font-size: 12px;
      position: absolute;
      left: -20px;
      transform: rotate(-90deg);
      transform-origin: top left 0px;
    }
  }

  .x-axis-arrow.axis {
    height: 3px;
    width: 75px;
    background-color: #000000;
  }
  &.quarter .x-axis-arrow.axis {
    width: 100px;
  }

  .y-axis-arrow.axis {
    height: 75px;
    width: 3px;
    background-color: #000000;
    position: absolute;
    bottom: 0px;
  }

  // &.disease {
  //   .y-axis.low {
  //     .low {
  //       background-color: #C0C0C0;
  //       border-left: 1px solid #b3b3b3;
  //     }
  //     .medium {
  //       background-color: #dfb0d6;
  //     }
  //     .high {
  //       background-color: #be65ac;
  //     }
  //   }
  // }
  &.disease.PuOr {
    .y-axis.low {
      .low {
        background-color: #c0c0c0;
        border-left: 1px solid #b3b3b3;
      }
      .medium {
        background-color: #cbb8d7;
      }
      .high {
        background-color: #9972af;
      }
    }
  }
  &.disease.GnBu {
    .y-axis.low {
      .low {
        background-color: #c0c0c0;
        border-left: 1px solid #b3b3b3;
      }
      .medium {
        background-color: #b8d6be;
      }
      .high {
        background-color: #73ae80;
      }
    }
  }
  &.disease.BuPu {
    .y-axis.low {
      .low {
        background-color: #c0c0c0;
        border-left: 1px solid #b3b3b3;
      }
      .medium {
        background-color: #dfb0d6;
      }
      .high {
        background-color: #be64ac;
      }
    }
  }
  &.disease.RdBu {
    .y-axis.low {
      .low {
        background-color: #c0c0c0;
        border-left: 1px solid #b3b3b3;
      }
      .medium {
        background-color: #b0d5df;
      }
      .high {
        background-color: #64acbe;
      }
    }
  }
}





.marker-legends {
  display: flex;
  .legend-item {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    background-color: #ffffff;
    i {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #ffffff;
      margin-right: 2px;
      border: 1px solid rgba(0, 0, 0, 0.9);
    }
    .icon.past-trial {
      background-color: #d52b1e;
    }
    .icon.upcoming-trial {
      background-color: #00af3f;
    }
    .icon.dct-element {
      background-color: #fff;
    }
  }
}

.county-tooltip-wrapper {
  max-width: 250px;
  p {
    margin-bottom: 10px;
    color: #666666;
    font-size: 0.65rem;
    font-weight: 500;
    word-break: break-all;
    &.county-name, &.state-name {
      color: #000000;
    }
    &.notice {
      word-break: normal;
    }
  }
}
.country-tooltip-wrapper{
  margin-bottom: 10px;
  padding-right: 20px;
  color: #666666;
  font-size: 0.65rem;
  font-weight: 500;
  word-break: break-all;
  max-width: 300px;
  .country-name{
      color: #000000;
  }
}

.color-schemes {
  .label {
    font-size: 1rem;
    margin-right: 10px;
  }
  background: #ffffff;
  padding: 5px 20px;
}

.info-wrapper {
  color: #666666;
  max-width: 200px;
  font-size: 0.75rem;
  font-weight: 500;
  p {
    margin-bottom: 10px;
  }
  .name {
    color: #000000;
  }
}

// #table-controller {
//   .btn-wrapper {
//     padding: 5px 10px;
//     background-color: #ffffff;
//     .ant-btn-primary,
//     .ant-btn-primary:hover {
//       color: #ffffff;
//     }
//   }
// }

.user-tips-modal {
  .ant-modal-footer {
    border-top: none;
  }
  .user-tips {
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    padding-top: 20px;
    .anticon.anticon-exclamation-circle {
      color: rgb(250, 173, 20);
      font-size: 22px;
      margin-right: 16px;
    }
  }
}

.legends-panel{
  position: fixed;
  top: 0;
  right: 0;
}

.status-legends{
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 50px;
  margin-left: 10px;
  .title {
    color: #393939;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 10px;
  };
  .item{
    padding: 2px 0;
    display: flex;
    align-items: center;
    span{
      padding-left: 5px;
      color: #737373;
      font-size: 10px;
      font-weight: 600;
    }
    .square-icon{
      height: 12px;
      width: 12px;
      background-color: #FF9046;
      // border: 1px solid #000000;
      margin-right: 4px;
      margin-left: 1px;
    }
  
  }
}
.site-status{
  border-bottom: 1px solid #666666;
  font-size: 10px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.triangle-icon{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #003399;
}

.square-icon{
  height: 12px;
  width: 12px;
  background-color: #FF9046;
  margin-right: 4px;
  margin-left: 1px;
}
.star-icon {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  
  margin-left: .9em;
  margin-right: .9em;
  margin-bottom: 1.2em;
  
  border-right:  .3em solid transparent;
  border-bottom: .7em  solid #00af3f;
  border-left:   .3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 8px;
  
  &:before, &:after {
    content: '';
    
    display: block;
    width: 0;
    height: 0;
    
    position: absolute;
    top: .6em;
    left: -1em;
  
    border-right:  1em solid transparent;
    border-bottom: .7em  solid #00af3f;
    border-left:   1em solid transparent;
  
    transform: rotate(-35deg);
  }
  
  &:after {  
    transform: rotate(35deg);
  }
}
