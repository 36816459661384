.choropleth-legends.quantitles{
    padding: 20px;
    margin-right: 50px;

    .x.axis-label{
        width: 100px;
    }
    .x-axis-arrow.axis{
        width: 100px;
     }
    .y-axis-arrow.axis{
        height: 100px;
    }
    .y.axis-label .anticon{
        bottom: 95px
    }
 

    .y-axis {
        line-height: 0;
    }
    i {
        display: inline-block;
        width: 24px;
        height: 24px;
    }
    .y-axis.high{
        .x.low{
           background-color: #9C438A; 
        }
        .x.medium{
            background-color: #81439C; 
         }
         .x.upper{
            background-color: #623171; 
         }
         .x.high{
            background-color: #2E204B; 
         }
    }
    .y-axis.upper{
        .x.low{
           background-color: #BE66AB; 
        }
        .x.medium{
            background-color: #8C64AA; 
         }
         .x.upper{
            background-color: #3A4894; 
         }
         .x.high{
            background-color: #303D75; 
         }
    }
    .y-axis.medium{
        .x.low{
           background-color: #DFB2D6; 
        }
        .x.medium{
            background-color: #A3AFD3; 
         }
         .x.upper{
            background-color: #5698B9; 
         }
         .x.high{
            background-color: #356278; 
         }
    }
    .y-axis.low{
        .x.low{
           background-color: #C0C0C0; 
        }
        .x.medium{
            background-color: #ADE3E4; 
         }
         .x.upper{
            background-color: #5AC8C8; 
         }
         .x.high{
            background-color: #3B9494; 
         }
    }

}